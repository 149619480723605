<template>
  <tfoms-panel
    class="analysis-tfoms__base-table"
    small
    title="Выполнение плана"
    subTitle="2024 год"
    iconColor="var(--v-success-base)"
  >
    <base-table
      :items="data"
      :headers="[
        { text: '', value: 'icon', width: '1%' },
        {
          text: '',
          value: 'name',
          cellClass: 'text-no-wrap',
        },
        {
          text: 'План',
          value: 'VolumePlanValue',
          align: 'center',
          width: '1%',
          cellClass: 'text-no-wrap',
        },
        {
          text: 'Факт',
          value: 'VolumeValue',
          align: 'center',
          cellClass: 'text-no-wrap',
          width: '1%',
        },
        { text: '', value: 'percent' },
      ]"
      hide-default-footer
      notShowSelect
      nested
      :showSettings="false"
    >
      <template slot="item.icon" slot-scope="{ item }">
        <div class="analysis-tfom__icon-wrapper">
          <m-icon :icon="item.icon.name" :color="item.icon.color"></m-icon>
        </div>
      </template>
      <template slot="item.name" slot-scope="{ item }">
        <div>{{ item.name.title }}</div>
        <div class="caption">{{ item.name.subTitle }}</div>
      </template>
      <template slot="item.VolumePlanValue" slot-scope="{ item }">
        {{ item.VolumePlanValue | numberSplit }}
      </template>
      <template slot="item.VolumeValue" slot-scope="{ item }">
        {{ item.VolumeValue | numberSplit
        }}<m-icon
          v-if="item.VolumeValue > item.VolumePlanValue"
          color="success"
          :icon="'mdi-arrow-up-thin'"
        /><m-icon
          v-if="item.VolumeValue < item.VolumePlanValue"
          color="error"
          :icon="'mdi-arrow-down-thin'"
        />
      </template>
      <template slot="item.percent" slot-scope="{ item }">
        <div class="d-flex align-center" style="min-width: 100px">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-progress-linear
                v-on="on"
                class="mr-2"
                :value="item.percent"
                style="border-radius: 20px"
                height="9"
                color="var(--v-success-base)"
              ></v-progress-linear>
            </template>
            <div>
              {{
                item.Labels && item.Labels.length >= 1
                  ? item.Labels[0]
                  : "План:"
              }}
              {{ item.VolumePlanValue }} {{ item.Unit }}
            </div>
            <div>
              {{
                item.Labels && item.Labels.length >= 2
                  ? item.Labels[1]
                  : "Факт:"
              }}
              {{ item.VolumeValue }} {{ item.Unit }}
            </div>
          </v-tooltip>
          {{ item.percent }}%
        </div>
      </template>
    </base-table>
  </tfoms-panel>
</template>
<script>
import tfomsPanel from "@/components/analysis/panel.vue";
import BaseTable from "@/components/base/BaseTable";
export default {
  components: {
    tfomsPanel,
    BaseTable,
  },
  inject: {
    provideUnits: {
      default: () => ({
        rub: null,
        unit: null,
      }),
    },
  },
  props: {
    value: {
      type: Object,
      default: () => ({
        Name: "Итог",
        VolumePlanValue: 0,
        VolumeValue: 0,
        FinancialPlanValue: 0,
        FinancialValue: 0,
        FineValue: 0,
        SanctionValue: 0,
      }),
    },
  },
  data() {
    return {
      data: [
        {
          icon: { name: "mdi-account-multiple", color: "#00B050" },
          name: {
            title: "Финансовое обеспечение",
            subTitle: this.provideUnits.rub.text,
          },
          VolumePlanValue: this.value.FinancialPlanValue,
          VolumeValue: this.value.FinancialValue,
          percent: 92,
          Unit: this.provideUnits.rub.text,
        },
      ],
    };
  },
  watch: {
    value: {
      deep: true,
      handler() {
        this.init();
      },
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.data[0].VolumePlanValue = this.value.VolumePlanValue;
      this.data[0].VolumeValue = this.value.VolumeValue;
      this.data[0].percent = Math.min(
        100,
        Math.round((this.value.VolumeValue / this.value.VolumePlanValue) * 100)
      );

      this.data[1].VolumePlanValue = this.value.FinancialPlanValue;
      this.data[1].VolumeValue = this.value.FinancialValue;
      this.data[1].percent = Math.min(
        100,
        Math.round(
          (this.value.FinancialValue / this.value.FinancialPlanValue) * 100
        )
      );
    },
  },
};
</script>
<style lang="scss"></style>
